import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { invoiceDateFormat } from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import { differenceBy, intersection, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import {
  InvoiceSigningUpdate,
  InvoiceSigningUpdateVariables,
} from 'ap/signing/transactionSigning/view/__generated__/InvoiceSigningUpdate';
import { validationSchema } from 'common/components/Modules/TransactionEdit/validation';
import {
  InvoiceDetails,
  InvoiceDetailsVariables,
} from 'common/components/Modules/TransactionEdit/graphql/__generated__/InvoiceDetails';
import {
  ApprovalHistoryItemsOrderBy,
  EntityDeleteInput,
  InvoiceDistributionPatch,
  InvoiceDistributionUpdateTypeInput,
  InvoicePatch,
} from 'common/types/globalTypes';
import { FormView } from './FormView';
import { getDefaultValues } from 'common/components/Modules/TransactionEdit/utils';
import {
  AccountingViewRowValues,
  FileListArray,
  TransactionSigningValues,
} from 'common/components/Modules/TransactionEdit/types';
import { setUserDefaults } from 'utility/cache/ui';

const GET_INVOICE_DETAILS = loader(
  '../../../../common/components/Modules/TransactionEdit/graphql/InvoiceDetails.graphql'
);
const UPDATE_INVOICE_SIGNING = loader(
  '../../../../ap/signing/transactionSigning/view/InvoiceSigningUpdate.graphql'
);
const GET_APPROVAL_DETAILS = loader('../../UserApproval.graphql');
const GET_APPROVAL_HISTORY_DETAILS = loader('../../ApprovalHistory.graphql');

const isNew = false;
interface TransactionEditProps {
  transactionSigningId: string | null | undefined;
  setShowTransactionModal: React.Dispatch<React.SetStateAction<boolean>>;
  approvalOrderBy: ApprovalHistoryItemsOrderBy[];
  approvalId: string | undefined;
  isHistory: boolean;
}

export const TransactionEdit: React.FC<TransactionEditProps> = ({
  transactionSigningId,
  setShowTransactionModal,
  approvalOrderBy,
  approvalId,
  isHistory,
}) => {
  const userDefaults = useReactiveVar(setUserDefaults);
  const { addToast } = useToasts();
  const fileListRef = useRef<FileListArray[] | undefined>();
  const {
    data: invoiceDetailsData,
    loading: invoiceDetailsLoading,
    refetch,
  } = useQuery<InvoiceDetails, InvoiceDetailsVariables>(GET_INVOICE_DETAILS, {
    variables: {
      id: transactionSigningId!,
    },
    skip: !transactionSigningId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const [updateTransaction, { loading: updateTransactionLoading }] =
    useMutation<InvoiceSigningUpdate, InvoiceSigningUpdateVariables>(
      UPDATE_INVOICE_SIGNING,
      { errorPolicy: 'all' }
    );

  const formMethods = useForm<TransactionSigningValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema()),
  });

  const { handleSubmit, trigger, reset } = { ...formMethods };

  const onHandleSubmit = async (values: TransactionSigningValues) => {
    const initialValues = getDefaultValues({
      isNew,
      invoiceDetailsData,
      userDefaults,
    });
    const {
      controlTotalAmount,
      description,
      invoiceDate,
      reimburseAccountReference,
      reimburseAmount,
      invoiceNumber,
      corporatePeriodId,
      vendorReference,
      departmentId,
      statusHintOverride,
      statusHintOverrideId,
      transactionTypeId,
      currencyId,
      bankAccountNumber,
      bankIbanCode,
      bankName,
      bankRoutingNumber,
      bankSortCode,
      bankSwiftCode,
      bankAccountName,
      cardHolderId,
      invoiceDistributions,
      businessUnitId,
    } = values;

    const invoiceValues = {
      controlTotalAmount: !!controlTotalAmount
        ? parseFloat(controlTotalAmount).toFixed(2).toString()
        : '0.00',
      description,
      invoiceDate: invoiceDate ? invoiceDateFormat(invoiceDate) : null,
      reimburseAccountReference,
      reimburseAmount,
      invoiceNumber,
      corporatePeriodId,
      vendorReference,
      departmentId,
      statusHintOverride,
      statusHintOverrideId,
      transactionTypeId: parseInt(transactionTypeId?.toString() || '0'),
      currencyId: parseInt(currencyId?.toString() || '0'),
      bankAccountNumber,
      bankIbanCode,
      bankName,
      bankRoutingNumber,
      bankSortCode,
      bankSwiftCode,
      bankAccountName,
      cardHolderId,
      businessUnitId,
    };

    const invoicePatch: InvoicePatch = Object.entries(invoiceValues).reduce(
      (res, [key, val]) => {
        if (val !== initialValues[key as keyof TransactionSigningValues]) {
          return { ...res, [key]: val };
        }
        return res;
      },
      {}
    );

    const newInvoiceDistributionRows =
      invoiceDistributions &&
      (invoiceDistributions
        ?.filter(
          (addr) => !addr.id && !Object.values(addr).every((x) => x === null)
        )
        .map(
          ({
            id,
            _rowTimestamp,
            _createdDate,
            _isDeletable,
            _isUpdatable,
            _ff1Name,
            _ff2Name,
            _ff3Name,
            _ff4Name,
            _accountName,
            ...addr
          }) => addr
        ) as InvoiceDistributionPatch[]);

    const deletedInvoiceDistributionRows =
      initialValues.invoiceDistributions &&
      (differenceBy(
        initialValues.invoiceDistributions,
        invoiceDistributions || [],
        'id'
      ).map((addr) => ({
        id: addr.id!,
        rowTimestamp: addr._rowTimestamp!,
      })) as EntityDeleteInput[]);

    const updatedInvoiceDistributions =
      initialValues.invoiceDistributions &&
      invoiceDistributions &&
      intersection(
        initialValues.invoiceDistributions.map((addr) => addr.id),
        invoiceDistributions.filter((addr) => addr.id).map((addr) => addr.id)
      ).reduce((arr, targetId) => {
        const initialInvoiceDistributions =
          initialValues.invoiceDistributions!.find(
            (addr) => addr.id === targetId
          )!;
        const { id, _rowTimestamp, _accountName, ...updatedAddress } =
          invoiceDistributions!.find((addr) => addr.id === targetId)!;
        const patch = Object.entries(updatedAddress).reduce(
          (res, [key, val]) => {
            if (
              val !==
              initialInvoiceDistributions[key as keyof AccountingViewRowValues]
            )
              return { ...res, [key]: val };
            return res;
          },
          {}
        );
        if (!isEmpty(patch))
          return [
            ...arr,
            {
              id,
              rowTimestamp: _rowTimestamp,
              invoiceDistributionPatch: patch,
            },
          ];
        return arr;
      }, [] as InvoiceDistributionUpdateTypeInput[]);

    if (transactionSigningId && invoiceDetailsData?.invoice?._rowTimestamp) {
      const { errors } = await updateTransaction({
        variables: {
          input: {
            id: transactionSigningId,
            rowTimestamp: invoiceDetailsData.invoice._rowTimestamp,
            invoiceDistributionsUpdate:
              updatedInvoiceDistributions &&
              updatedInvoiceDistributions.length > 0
                ? updatedInvoiceDistributions
                : undefined,
            invoiceDistributionsCreate:
              newInvoiceDistributionRows &&
              newInvoiceDistributionRows.length > 0
                ? newInvoiceDistributionRows
                : undefined,
            invoiceDistributionsDelete:
              deletedInvoiceDistributionRows &&
              deletedInvoiceDistributionRows.length > 0
                ? deletedInvoiceDistributionRows
                : undefined,
            invoicePatch: !isEmpty(invoicePatch) ? invoicePatch : undefined,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_INVOICE_DETAILS,
            variables: {
              id: transactionSigningId,
            },
          },
          {
            query: isHistory
              ? GET_APPROVAL_HISTORY_DETAILS
              : GET_APPROVAL_DETAILS,
            variables: {
              id: approvalId,
              orderBy: approvalOrderBy,
            },
          },
        ],
      });
      if (errors?.length) {
        addToast(errors[0].message, {
          appearance: 'error',
        });
      } else {
        addToast('Transaction edited successfully', {
          appearance: 'success',
        });
      }
    }
  };
  const triggerCallBack = useCallback(trigger, []);

  useEffect(() => {
    triggerCallBack();
  }, [triggerCallBack]);

  useEffect(() => {
    const defaultValues = getDefaultValues({
      isNew,
      invoiceDetailsData,
      userDefaults,
    });

    reset(defaultValues);
  }, [invoiceDetailsData, reset, userDefaults]);

  return (
    <>
      <FormProvider {...formMethods}>
        <FormView
          onSave={handleSubmit(onHandleSubmit)}
          onDismiss={() => setShowTransactionModal(false)}
          isCreating={false}
          isNew={false}
          isSaveAnother={(value) => false}
          isUpdating={updateTransactionLoading}
          invoiceDetailsData={invoiceDetailsData}
          dataLoading={invoiceDetailsLoading}
          refetch={refetch}
          onFileListArrayChange={(files) => (fileListRef.current = files)}
        />
      </FormProvider>
    </>
  );
};
