import { IconButton, Stack } from '@fluentui/react';
import clsx from 'clsx';
import {
  FormikDropdown,
  FormikCheckBox,
  FormikNumberTextField,
  AmountTextField,
} from 'common/components';
import { FormikTextField } from 'common/components/FormikFields/TextField';
import React, { useCallback, useEffect, useState } from 'react';
import { ApprovalSetupCommonData } from 'settings/account/approvalSetup/__generated__/ApprovalSetupCommonData';
import { ApprovalBranchViewRowValues, ApprovalValues } from '../../types';
import { useStyles } from './index.styles';
import { formatDropdownOptions } from 'common/utils';
import { useFormikContext } from 'formik';

interface ApproverDataItemProps {
  data: ApprovalBranchViewRowValues;
  approvalLevelsName: string;
  distributionTypes: ApprovalSetupCommonData;
  index: number;
  rowIndex: number;
  lastRow: number;
  isDisabled: boolean;
  insertAdditionalRow: () => void;
  deleteCurrentRow: (index: number) => void;
}
const accountingInputs = {
  branchOrder: { width: 100, title: '' },
  description: { width: 140, title: '' },
  occupationId: { width: 260, title: '' },
  rankId: { width: 260, title: ' ' },
  budgetLimitAmount: { width: 130, title: ' ' },
  budgetLimitPercent: { width: 130, title: ' ' },
  isOverBudgetSelector: { width: 130, title: ' ' },
  isBusinessUnitSelector: { width: 130, title: '' },
  isUserGroupSelector: {
    width: 130,
    title: '',
  },
  isDepartmentSelector: {
    width: 130,
    title: '',
  },
  isProjectSelector: {
    width: 130,
    title: '',
  },
  transactionStartAmountTotal: {
    width: 130,
    title: '',
  },
  transactionEndAmountTotal: {
    width: 130,
    title: '',
  },
  transactionAmountConversionTypeId: {
    width: 130,
    title: '',
  },
  approverTransactionTypeId: {
    width: 130,
    title: '',
  },
  transactionBusinessUnitId: {
    width: 130,
    title: '',
  },
  transactionDepartmentId: {
    width: 130,
    title: '',
  },
  transactionDepartmentGroupId: {
    width: 130,
    title: '',
  },
  isTransactionNotCondition: {
    width: 130,
    title: '',
  },
  approverDelayDays: {
    width: 60,
    title: '',
  },
  approverDelayHours: {
    width: 60,
    title: '',
  },
  approverDelayStatusTypeId: {
    width: 130,
    title: '',
  },
};

export const ApproverDataItem: React.FC<ApproverDataItemProps> = ({
  data,
  approvalLevelsName,
  distributionTypes,
  index,
  lastRow,
  insertAdditionalRow,
  deleteCurrentRow,
  isDisabled,
  rowIndex,
}) => {
  const styles = useStyles();
  const [showRemoveIcon, setShowRemoveIcon] = useState<boolean>(false);

  const occupations =
    formatDropdownOptions(distributionTypes?.userOccupationTitles?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.userOccupationTitle!,
    }) || [];

  const ranks =
    formatDropdownOptions(distributionTypes?.userRankTypes?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.userRank!,
    }) || [];

  const businessUnitOptions =
    formatDropdownOptions(distributionTypes?.businessUnits?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
    }) || [];

  const departmentOptions =
    formatDropdownOptions(distributionTypes?.departments?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
    }) || [];

  const departmentGroupsOptions =
    formatDropdownOptions(distributionTypes?.departmentGroups?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.name!,
    }) || [];

  const conversionOptions =
    formatDropdownOptions(distributionTypes?.exchangeRateTypes?.nodes, {
      getKey: (item) => item.id,
      getText: (item) => item.phaseName || '',
      includeAll: false,
    }) || [];
  const transactionTypesOptions =
    formatDropdownOptions(distributionTypes?.approverTransactionTypes?.nodes, {
      getKey: (item) => item.id ?? '',
      getText: (item) => item.approvalTransactionType || '',
      includeAll: false,
    }) || [];
  const delayTypesOptions =
    formatDropdownOptions(distributionTypes?.approverDelayTypes?.nodes, {
      getKey: (item) => item.id ?? '',
      getText: (item) => item.delayType || '',
      includeAll: false,
    }) || [];

  const handleDataUpdate = () => {
    const edited = Object.values(data).filter(
      (field) => field !== null && field !== ''
    );
    if (edited.length > 0 && lastRow === index && !isDisabled) {
      if (data.description) {
        insertAdditionalRow();
      }
    }
  };

  const handleDataUpdateMemo = useCallback(handleDataUpdate, [data]);
  useEffect(() => {
    handleDataUpdateMemo();
  }, [handleDataUpdateMemo]);

  const removeCurrentIndex = () => {
    deleteCurrentRow(index);
  };

  const handleDeleteRow = () => {
    const edited = Object.values(data).filter(
      (field) => field !== null && field !== ''
    );
    const allNull = Object.values(data).every((values) => values === null);
    if (edited.length > 0) {
      setShowRemoveIcon(true);
    } else {
      setShowRemoveIcon(false);
    }
    if (allNull && lastRow !== index) {
      deleteCurrentRow(index);
    }
  };
  const handleDeleteRowMemo = useCallback(handleDeleteRow, [data]);
  useEffect(() => {
    handleDeleteRowMemo();
  }, [handleDeleteRowMemo]);
  const { values, setFieldValue } = useFormikContext<ApprovalValues>();
  const isBudget =
    values.approvalTrees?.[rowIndex].approvalBranches?.[index]
      .isOverBudgetSelector;
  return (
    <Stack className={styles.container}>
      <Stack className={styles.renderAccountingOptions}>
        <Stack
          horizontal
          key={index}
          className={clsx(index !== 0 && styles.accountingRowMargin)}
        >
          <Stack
            tokens={{ padding: '0px 40px 0px 0px' }}
            className={styles.fullWidthContainer}
            horizontal
            horizontalAlign="space-between"
          >
            <Stack className={styles.removeIconContainer}>
              {showRemoveIcon && !isDisabled && (
                <IconButton
                  onClick={removeCurrentIndex}
                  iconProps={{ iconName: 'Blocked2Solid' }}
                  ariaLabel="delete"
                  className={styles.deleteIconColor}
                />
              )}
            </Stack>
            <Stack className={styles.marginLeft5}>
              <FormikNumberTextField
                disabled={isDisabled}
                className={styles.marginL5}
                name={`${approvalLevelsName}.branchOrder`}
                style={{
                  width: accountingInputs['branchOrder'].width,
                }}
                underlined
              />
            </Stack>
            <Stack className={styles.marginLeft5}>
              <FormikTextField
                disabled={isDisabled}
                className={styles.marginL5}
                name={`${approvalLevelsName}.description`}
                style={{
                  width: accountingInputs['description'].width,
                }}
                underlined
              />
            </Stack>
            <Stack className={styles.dropDownContainer260}>
              <FormikDropdown
                styles={{
                  root: {
                    maxWidth: 260,
                  },
                }}
                underlined
                dropdownWidth="auto"
                options={occupations}
                name={`${approvalLevelsName}.userOccupation.id`}
                disabled={isDisabled}
              />
            </Stack>
            <Stack className={styles.dropDownContainer260}>
              <FormikDropdown
                styles={{
                  root: {
                    maxWidth: 260,
                  },
                }}
                underlined
                dropdownWidth="auto"
                options={ranks}
                name={`${approvalLevelsName}.userRank.id`}
                disabled={isDisabled}
              />
            </Stack>
            <Stack className={styles.marginLeft5}>
              <Stack className={styles.formikCheckboxStyle}>
                <FormikCheckBox
                  disabled={isDisabled}
                  boxSide="end"
                  name={`${approvalLevelsName}.isBusinessUnitSelector`}
                />
              </Stack>
            </Stack>
            <Stack className={styles.marginLeft5}>
              <Stack className={styles.formikCheckboxStyle}>
                <FormikCheckBox
                  disabled={isDisabled}
                  boxSide="end"
                  name={`${approvalLevelsName}.isUserGroupSelector`}
                />
              </Stack>
            </Stack>
            <Stack className={styles.marginLeft5}>
              <Stack className={styles.formikCheckboxStyle}>
                <FormikCheckBox
                  disabled={isDisabled}
                  boxSide="end"
                  name={`${approvalLevelsName}.isDepartmentSelector`}
                />
              </Stack>
            </Stack>
            <Stack className={styles.marginLeft5}>
              <Stack className={styles.formikCheckbox}>
                <FormikCheckBox
                  disabled={isDisabled}
                  boxSide="end"
                  name={`${approvalLevelsName}.isProjectSelector`}
                />
              </Stack>
            </Stack>
            <Stack className={styles.marginLeft5}>
              <Stack className={styles.formikCheckbox}>
                <FormikCheckBox
                  disabled={isDisabled}
                  boxSide="end"
                  name={`${approvalLevelsName}.isOverBudgetSelector`}
                  onChange={(_, value) => {
                    if (!value) {
                      setFieldValue(
                        `${approvalLevelsName}.budgetLimitAmount`,
                        null
                      );
                      setFieldValue(
                        `${approvalLevelsName}.budgetLimitPercent`,
                        null
                      );
                    }
                  }}
                />
              </Stack>
            </Stack>
            <Stack className={styles.marginLeft5}>
              <AmountTextField
                disabled={isDisabled || !isBudget}
                className={styles.marginL5}
                name={`${approvalLevelsName}.budgetLimitAmount`}
                style={{
                  width: accountingInputs['budgetLimitAmount'].width,
                }}
                underlined
              />
            </Stack>
            <Stack className={styles.width30}></Stack>
            <Stack className={styles.marginLeft5}>
              <FormikNumberTextField
                disabled={isDisabled || !isBudget}
                className={styles.marginL5}
                name={`${approvalLevelsName}.budgetLimitPercent`}
                style={{
                  width: accountingInputs['budgetLimitPercent'].width,
                }}
                underlined
              />
            </Stack>
            <Stack className={styles.marginLeft5}>
              <AmountTextField
                disabled={isDisabled}
                className={styles.marginL5}
                name={`${approvalLevelsName}.transactionStartAmountTotal`}
                style={{
                  width: accountingInputs['transactionStartAmountTotal'].width,
                }}
                underlined
              />
            </Stack>
            <Stack className={styles.marginLeft5}>
              <AmountTextField
                disabled={isDisabled}
                className={styles.marginL5}
                name={`${approvalLevelsName}.transactionEndAmountTotal`}
                style={{
                  width: accountingInputs['transactionEndAmountTotal'].width,
                }}
                underlined
              />
            </Stack>
            <Stack className={styles.dropDownContainer}>
              <FormikDropdown
                styles={{
                  root: {
                    maxWidth: 136,
                  },
                }}
                underlined
                dropdownWidth="auto"
                options={conversionOptions}
                name={`${approvalLevelsName}.transactionAmountConversionTypeId`}
                disabled={isDisabled}
              />
            </Stack>
            <Stack className={styles.dropDownContainer}>
              <FormikDropdown
                styles={{
                  root: {
                    maxWidth: 136,
                  },
                }}
                underlined
                dropdownWidth="auto"
                options={transactionTypesOptions}
                name={`${approvalLevelsName}.approverTransactionTypeId`}
                disabled={isDisabled}
              />
            </Stack>
            <Stack className={styles.dropDownContainer}>
              <FormikDropdown
                styles={{
                  root: {
                    maxWidth: 136,
                  },
                }}
                underlined
                dropdownWidth="auto"
                options={businessUnitOptions}
                name={`${approvalLevelsName}.transactionBusinessUnitId`}
                disabled={isDisabled}
              />
            </Stack>
            <Stack className={styles.dropDownContainer}>
              <FormikDropdown
                styles={{
                  root: {
                    maxWidth: 136,
                  },
                }}
                underlined
                dropdownWidth="auto"
                options={departmentOptions}
                name={`${approvalLevelsName}.transactionDepartmentId`}
                disabled={isDisabled}
              />
            </Stack>
            <Stack className={styles.dropDownContainer}>
              <FormikDropdown
                styles={{
                  root: {
                    maxWidth: 136,
                  },
                }}
                underlined
                dropdownWidth="auto"
                options={departmentGroupsOptions}
                name={`${approvalLevelsName}.transactionDepartmentGroupId`}
                disabled={isDisabled}
              />
            </Stack>
            <Stack className={styles.marginLeft5}>
              <Stack className={styles.formikCheckboxStyle}>
                <FormikCheckBox
                  disabled={isDisabled}
                  boxSide="end"
                  name={`${approvalLevelsName}.isTransactionNotCondition`}
                />
              </Stack>
            </Stack>
            {/* new fields below */}
            <Stack className={styles.dropDownContainer}>
              <FormikDropdown
                styles={{
                  root: {
                    maxWidth: 136,
                  },
                }}
                underlined
                dropdownWidth="auto"
                options={delayTypesOptions}
                name={`${approvalLevelsName}.approverDelayStatusTypeId`}
                disabled={isDisabled}
              />
            </Stack>
            <Stack className={styles.marginLeft5}>
              <AmountTextField
                disabled={isDisabled}
                className={styles.marginL5}
                name={`${approvalLevelsName}.approverDelayDays`}
                style={{
                  width: accountingInputs['approverDelayDays'].width,
                }}
                underlined
              />
            </Stack>
            <Stack className={styles.marginLeft5}>
              <AmountTextField
                disabled={isDisabled}
                className={styles.marginL5}
                name={`${approvalLevelsName}.approverDelayHours`}
                style={{
                  width: accountingInputs['approverDelayHours'].width,
                }}
                underlined
              />
            </Stack>
            {/* new fields ends */}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
