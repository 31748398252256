import {
  makeVar,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  DefaultButton,
  ProgressIndicator,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ApprovalButtons } from 'approvals/ApprovalInputGroup';
import { setApprovalLayoutType } from 'approvals/MyApprovals';
import {
  ApprovalHistoryActions,
  ApprovalHistoryActionsVariables,
} from 'approvals/__generated__/ApprovalHistoryActions';
import {
  ApprovalUserActions,
  ApprovalUserActionsVariables,
} from 'approvals/__generated__/ApprovalUserActions';
import { ApprovalPanelTypes } from 'approvals/types';
import {
  setApprovalHistoryNodes,
  setApprovalNodes,
  setLastApprovalAction,
  setNextApproval,
} from 'approvals/ui';
import DraggablePanel from 'common/components/DraggablePanel';
import { HierarchyModal } from 'common/components/HierarchyModal';
import { PanelHeader } from 'common/components/PanelHeader';
import { StamperView } from 'common/components/StamperView';
import { StatusIcon } from 'common/components/StatusIcon';
import {
  PaymentDocumentPackageStatus,
  PaymentDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/PaymentDocumentPackageStatus';
import {
  PurchaseOrderDocumentPackageStatus,
  PurchaseOrderDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/PurchaseOrderDocumentPackageStatus';
import {
  TransactionDocumentPackageStatus,
  TransactionDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/TransactionDocumentPackageStatus';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalActionTypeInput,
  DocumentPackageStatusType,
  TransactionLayout,
} from 'common/types/globalTypes';
import { PanelCommonProps } from 'common/types/utility';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { AddApproverModal } from './AdditionalApprover';
import { AdditionalInformation } from './AdditionalInformation';
import { BatchTransactionLayout } from './BatchTransactionLayout';
import { DocumentPackage } from './DocumentPackage';
import { InvoiceHeader } from './Header';
import { InvoiceDetailedData } from './InvoiceDetailedData';
import { InvoiceAccountingList } from './InvoiceList';
import { InvoicePaymentsList } from './InvoiceListPayCycle';
import { MenuActions } from './MenuActions';
import { POItems } from './POItems';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { SignatureView } from './Signature';
import { TripList } from './Trips/TripList';
import {
  ApprovalHistory,
  ApprovalHistoryVariables,
} from './__generated__/ApprovalHistory';
import {
  userApproval,
  userApprovalVariables,
} from './__generated__/userApproval';
import { useStyles } from './index.styles';
import { getApprovalOrderBy, isLayoutPaycycle } from './utils';
import {
  TravelAuthorizationDocumentPackageStatus,
  TravelAuthorizationDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/TravelAuthorizationDocumentPackageStatus';
import { setActiveOrderIndex } from 'utility/cache/ui';

const GET_APPROVAL_DETAILS = loader('./UserApproval.graphql');
const GET_APPROVAL_HISTORY_DETAILS = loader('./ApprovalHistory.graphql');
const APPROVAL_HISTORY_ACTIONS = loader('../ApprovalHistoryActions.graphql');
const APPROVAL_USER_ACTIONS = loader('../ApprovalUserActions.graphql');
const DOCUMENT_PACKAGE_STATUS = loader(
  '../../common/graphql/DocumentPackageSubscription/TransactionPackageStatus.graphql'
);
const PO_DOCUMENT_PACKAGE_STATUS = loader(
  '../../common/graphql/DocumentPackageSubscription/PurchaseOrderDocumentPackageStatus.graphql'
);
const TA_DOCUMENT_PACKAGE_STATUS = loader(
  '../../common/graphql/DocumentPackageSubscription/TravelAuthorizationDocumentPackageStatus.graphql'
);
const PAYMENT_DOCUMENT_PACKAGE_STATUS = loader(
  '../../common/graphql/DocumentPackageSubscription/PaymentDocumentPackageStatus.graphql'
);
const USER_PENDING_APPROVAL = loader(
  '../../common/graphql/UserPendingApprovalCount.graphql'
);

export const setApprovalPanelReactive = makeVar<boolean>(false);

export const ApprovalDetails = () => {
  const approvalLayoutType = useReactiveVar(setApprovalLayoutType);
  const styles = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();
  const commonStyles = useCommonStyles();
  const [isOpen, { setFalse: dismissPanel }] = useBoolean(true);
  const approvalCheckList = useReactiveVar(setApprovalNodes);
  const lastApprovalAction = useReactiveVar(setLastApprovalAction);
  const nextApproval = useReactiveVar(setNextApproval);
  const approvalCheckListLength = approvalCheckList?.length! || 0;
  const { approvalId } = useParams<{ approvalId: string | undefined }>();
  const [approvalState, setApprovalState] = useState<
    ApprovalActionTypeInput | undefined
  >();
  const [processDone, setProcessDone] = useState<boolean>(false);
  const client = useApolloClient();

  const [processApproval, { loading: processApprovalLoading }] = useMutation<
    ApprovalUserActions,
    ApprovalUserActionsVariables
  >(APPROVAL_USER_ACTIONS, { errorPolicy: 'all' });

  const approvalOrderBy = getApprovalOrderBy(approvalLayoutType);
  const {
    data: approvalDetails,
    loading: approvalLoading,
    variables,
    refetch: refetchUserApproval,
  } = useQuery<userApproval, userApprovalVariables>(GET_APPROVAL_DETAILS, {
    variables: {
      id: approvalId!,
      orderBy: approvalOrderBy,
    },
    skip: !approvalId,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });
  const position = approvalCheckList?.findIndex(
    (item) => item.id === approvalDetails?.userApproval?.id
  );
  const navigateToPosition = position + 1;
  const hasMoreApproval = navigateToPosition < approvalCheckListLength;

  useEffect(() => {
    if (approvalDetails?.userApproval?.iconType?.isApprovalActionAvailable) {
      setApprovalState({
        ...lastApprovalAction!,
        entityApprovalBranchId:
          approvalDetails.userApproval.entityApprovalBranchId!,
        entityApprovalBranchRowTimestamp:
          approvalDetails.userApproval.entityApprovalBranchRowTimestamp!,
        isApproveBypassLowerLevels: undefined,
        comments: undefined,
      });
    } else {
      setApprovalState(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalDetails]);

  // Clearing Saved Approval Action so that its not cloned after closing a panel
  useEffect(() => {
    setLastApprovalAction(undefined);
    setApprovalPanelReactive(true);
    // Cleanup function to be executed on component unmount
    return () => {
      setApprovalPanelReactive(false);
    };
  }, []);

  const onProcessApproval = async (willApproveNext: boolean) => {
    if (approvalState) {
      setNextApproval(willApproveNext);
      if (willApproveNext) setLastApprovalAction(approvalState);
      else setLastApprovalAction(undefined);
      const { errors, data } = await processApproval({
        variables: {
          input: {
            approveDeclineActions: [approvalState],
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: approvalId
          ? [
              {
                query: GET_APPROVAL_DETAILS,
                variables,
              },
              {
                query: USER_PENDING_APPROVAL,
              },
            ]
          : [
              {
                query: USER_PENDING_APPROVAL,
              },
            ],
      });
      if (errors?.length)
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
      else {
        setProcessDone(true);
        addToast('Your approval have been processed', {
          appearance: 'success',
        });
        if (hasMoreApproval && willApproveNext) {
          history.replace(
            `/approvals/approval/${approvalCheckList[navigateToPosition]?.id}`
          );
        }

        const { isApproved, approvalType, entityId } = {
          ...data?.approvalUserActions?.approvalActionResults?.[0]
            ?.userApproval,
        };

        if (isApproved && entityId) {
          if (approvalType?._isSigningStandalone) {
            const observer = client.subscribe<
              TransactionDocumentPackageStatus,
              TransactionDocumentPackageStatusVariables
            >({
              query: DOCUMENT_PACKAGE_STATUS,
              variables: {
                id: entityId,
              },
            });

            const subscription = observer.subscribe(({ data, errors }) => {
              if (errors)
                addToast(
                  'Errors received while Subscribing to document package',
                  { appearance: 'error' }
                );
              else {
                const { document, status } = {
                  ...data?.transactionDocumentPackageStatus,
                };
                if (status === DocumentPackageStatusType.REGENERATION_FAILURE) {
                  addToast(
                    'Report generation failed. Document package was not created',
                    { appearance: 'error' }
                  );
                }
                if (status === DocumentPackageStatusType.FAILURE) {
                  addToast('Error while creating Document package ', {
                    appearance: 'error',
                  });
                }
                if (document) {
                  addToast('Document package created', {
                    appearance: 'success',
                  });
                  client.cache.modify({
                    id: client.cache.identify({
                      ...approvalDetails?.userApproval,
                    }),
                    fields: {
                      _documentPackageId: () => {
                        return document.id;
                      },
                    },
                  });
                }
              }
              subscription.unsubscribe();
            });
          } else if (approvalType?._isPurchaseOrderSystem) {
            const observer = client.subscribe<
              PurchaseOrderDocumentPackageStatus,
              PurchaseOrderDocumentPackageStatusVariables
            >({
              query: PO_DOCUMENT_PACKAGE_STATUS,
              variables: {
                id: entityId,
              },
            });

            const subscription = observer.subscribe(({ data, errors }) => {
              if (errors)
                addToast(
                  'Errors received while Subscribing to document package',
                  { appearance: 'error' }
                );
              else {
                const { document, status } = {
                  ...data?.purchaseOrderDocumentPackageStatus,
                };
                if (status === DocumentPackageStatusType.REGENERATION_FAILURE) {
                  addToast(
                    'Report generation failed. Document package was not created',
                    { appearance: 'error' }
                  );
                }
                if (status === DocumentPackageStatusType.FAILURE) {
                  addToast('Error while creating Document package ', {
                    appearance: 'error',
                  });
                }
                if (document) {
                  addToast('Document package created', {
                    appearance: 'success',
                  });
                  client.cache.modify({
                    id: client.cache.identify({
                      ...approvalDetails?.userApproval,
                    }),
                    fields: {
                      _documentPackageId: () => {
                        return document.id;
                      },
                    },
                  });
                }
              }
              subscription.unsubscribe();
            });
          } else if (approvalType?._isPaymentTrackingStandalone) {
            const observer = client.subscribe<
              PaymentDocumentPackageStatus,
              PaymentDocumentPackageStatusVariables
            >({
              query: PAYMENT_DOCUMENT_PACKAGE_STATUS,
              variables: {
                id: entityId,
              },
            });

            const subscription = observer.subscribe(({ data, errors }) => {
              if (errors)
                addToast(
                  'Errors received while Subscribing to document package',
                  { appearance: 'error' }
                );
              else {
                const { document, status } = {
                  ...data?.paymentDocumentPackageStatus,
                };
                if (status === DocumentPackageStatusType.REGENERATION_FAILURE) {
                  addToast(
                    'Report generation failed. Document package was not created',
                    { appearance: 'error' }
                  );
                }
                if (status === DocumentPackageStatusType.FAILURE) {
                  addToast('Error while creating Document package ', {
                    appearance: 'error',
                  });
                }
                if (document) {
                  addToast('Document package created', {
                    appearance: 'success',
                  });
                  client.cache.modify({
                    id: client.cache.identify({
                      ...approvalDetails?.userApproval,
                    }),
                    fields: {
                      _documentPackageId: () => {
                        return document.id;
                      },
                    },
                  });
                }
              }
              subscription.unsubscribe();
            });
          } else if (approvalType?._isTravelAuthSystem) {
            // TA_DOCUMENT_PACKAGE_STATUS

            const observer = client.subscribe<
              TravelAuthorizationDocumentPackageStatus,
              TravelAuthorizationDocumentPackageStatusVariables
            >({
              query: TA_DOCUMENT_PACKAGE_STATUS,
              variables: {
                id: entityId,
              },
            });

            const subscription = observer.subscribe(({ data, errors }) => {
              if (errors)
                addToast(
                  'Errors received while Subscribing to document package',
                  { appearance: 'error' }
                );
              else {
                const { document, status } = {
                  ...data?.travelAuthorizationDocumentPackageStatus,
                };
                if (status === DocumentPackageStatusType.REGENERATION_FAILURE) {
                  addToast(
                    'Report generation failed. Document package was not created',
                    { appearance: 'error' }
                  );
                }
                if (status === DocumentPackageStatusType.FAILURE) {
                  addToast('Error while creating Document package ', {
                    appearance: 'error',
                  });
                }
                if (document) {
                  addToast('Document package created', {
                    appearance: 'success',
                  });
                  client.cache.modify({
                    id: client.cache.identify({
                      ...approvalDetails?.userApproval,
                    }),
                    fields: {
                      _documentPackageId: () => {
                        return document.id;
                      },
                    },
                  });
                }
              }
              subscription.unsubscribe();
            });
          } else {
          }
        }
      }
    }
    if (!processApprovalLoading) setApprovalState(undefined);
  };

  const _onRenderHeader = () => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/approvals', { processDone });
          dismissPanel();
          setLastApprovalAction(undefined);
          setApprovalLayoutType(undefined);
        }}
      >
        <Stack
          grow
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack verticalAlign="start">
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Text variant="xLarge">Approval</Text>
              {approvalDetails?.userApproval?.approvalType?.approvalType && (
                <Text
                  variant="xLarge"
                  className={commonStyles.colorThemePrimary}
                >
                  {approvalDetails?.userApproval?.approvalType?.approvalType}
                </Text>
              )}
              <StatusIcon
                approval
                iconType={approvalDetails?.userApproval?.iconType!}
              />

              <DocumentPackage
                documentPackageId={
                  approvalDetails?.userApproval?._documentPackageId
                }
              />
            </Stack>
            <Stack>
              <Text className={commonStyles.colorThemeRed}>
                {approvalDetails?.userApproval?.additionalApprovalInformation}
              </Text>
            </Stack>
          </Stack>

          {approvalDetails?.userApproval
            ?._accountingStampTransactionReference && (
            <StamperView
              invoiceDetails={{
                _accountingStampDate:
                  approvalDetails?.userApproval?._accountingStampDate,
                _accountingStampTransactionReference:
                  approvalDetails?.userApproval
                    ?._accountingStampTransactionReference,
                _accountingStampUserName:
                  approvalDetails?.userApproval?._accountingStampUserName,
                _isAccountingEntryStampedComplete:
                  approvalDetails?.userApproval
                    ?._isAccountingEntryStampedCompleted,
                _isTransactionCancelled:
                  approvalDetails?.userApproval?._isTransactionCancelled,
              }}
            />
          )}
        </Stack>
      </PanelHeader>
    );
  };

  const _onRenderFooter = () => {
    return (
      <Stack className={styles.footerContainer}>
        <Stack
          horizontal
          tokens={{ padding: 25 }}
          className={styles.footerStyle}
          horizontalAlign={'space-between'}
        >
          {approvalDetails?.userApproval?.iconType
            ?.isApprovalActionAvailable && (
            <ApprovalButtons
              selectedApproval={{
                ...lastApprovalAction!,
                entityApprovalBranchId:
                  approvalDetails.userApproval.entityApprovalBranchId!,
                entityApprovalBranchRowTimestamp:
                  approvalDetails.userApproval
                    .entityApprovalBranchRowTimestamp!,
                isApproveBypassLowerLevels: undefined,
                comments: undefined,
              }}
              approvalData={approvalDetails?.userApproval!}
              onChange={(inputValue) => {
                setApprovalState(inputValue);
                setLastApprovalAction(inputValue);
              }}
            />
          )}

          <Stack horizontal>
            <Stack tokens={{ padding: '0px 20px' }}>
              {approvalDetails?.userApproval?.isApprovalTreeViewerAllowed && (
                <HierarchyModal
                  isApprovalEntry
                  entityId={approvalDetails?.userApproval?.id!}
                />
              )}
            </Stack>
            {approvalDetails?.userApproval?.isApproverAddingAllowed && (
              <AddApproverModal
                approvalId={approvalDetails?.userApproval?.id}
                entityApprovalBranchId={
                  approvalDetails?.userApproval?.entityApprovalBranchId!
                }
                approvalOrderBy={approvalOrderBy}
              />
            )}
          </Stack>
        </Stack>
        {approvalState?.isApproval && (
          <>
            <Stack className={styles.footerBackground}>
              {processApprovalLoading && <ProgressIndicator />}
            </Stack>

            <Stack
              className={styles.footerBackground}
              horizontal
              tokens={{ padding: 20, childrenGap: 10 }}
            >
              <Stack grow={1.5}>
                <DefaultButton
                  text="Confirm Approval"
                  primary={hasMoreApproval ? !nextApproval : true}
                  styles={{ root: { height: 50 } }}
                  disabled={processApprovalLoading}
                  onClick={() => onProcessApproval(false)}
                />
              </Stack>
              {hasMoreApproval && (
                <Stack grow={0.5}>
                  <DefaultButton
                    disabled={processApprovalLoading}
                    text="Confirm Approval + Select Next Approval"
                    primary={nextApproval}
                    styles={{ root: { height: 50 } }}
                    onClick={() => {
                      const current = setActiveOrderIndex();
                      current.clear();
                      setActiveOrderIndex(current);
                      onProcessApproval(true);
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </>
        )}

        {approvalState?.isDecline && (
          <Stack className={styles.footerBackground} tokens={{ padding: 20 }}>
            {processApprovalLoading && <ProgressIndicator />}
            <DefaultButton
              toggle
              primary={true}
              disabled={processApprovalLoading}
              className={styles.declineButton}
              selected={true}
              onClick={() => onProcessApproval(false)}
            >
              Confirm Decline
            </DefaultButton>
          </Stack>
        )}
      </Stack>
    );
  };

  const isMiscellaneousApproval =
    approvalDetails?.userApproval?.layoutType ===
      TransactionLayout.MISCELLANEOUS_APPROVAL &&
    approvalDetails?.userApproval?.approvalHistoryItemsByDisplayEntityApprovalId
      ?.nodes.length > 0;

  const isBatchTransaction =
    approvalDetails?.userApproval?.layoutType ===
    TransactionLayout.BATCH_TRANSACTION_APPROVAL;

  const { layoutType } = { ...approvalDetails?.userApproval };

  return (
    <DraggablePanel
      initialWidth={1000}
      minWidth={1000}
      {...PanelCommonProps}
      isBlocking={false}
      onRenderHeader={_onRenderHeader}
      onRenderFooter={_onRenderFooter}
      isOpen={isOpen}
    >
      {approvalLoading ? (
        <ShimmerView />
      ) : (
        <>
          <InvoiceHeader data={approvalDetails?.userApproval} />
          <Separator />
          {approvalDetails?.userApproval && (
            <MenuActions
              data={approvalDetails?.userApproval}
              approvalPanelType={ApprovalPanelTypes.USER_APPROVAL}
              onRefetch={() => refetchUserApproval?.()}
            />
          )}
          <Separator />
          <InvoiceDetailedData
            data={approvalDetails?.userApproval}
            approvalOrderBy={approvalOrderBy}
          />
          <Separator />
          {isMiscellaneousApproval && (
            <>
              <AdditionalInformation data={approvalDetails?.userApproval} />
              <Separator />
            </>
          )}
          {isBatchTransaction && (
            <BatchTransactionLayout
              data={
                approvalDetails?.userApproval
                  ?.approvalHistoryItemsByDisplayEntityApprovalId!
              }
            />
          )}

          {approvalDetails?.userApproval
            ?.approvalHistoryItemsByDisplayEntityApprovalId?.nodes &&
            approvalDetails.userApproval
              .approvalHistoryItemsByDisplayEntityApprovalId.nodes.length > 0 &&
            !isMiscellaneousApproval &&
            !isBatchTransaction && (
              <Stack tokens={{ padding: '0px 0px 0px 10px' }}>
                {isLayoutPaycycle(approvalDetails?.userApproval.layoutType!) ? (
                  <InvoicePaymentsList
                    data={
                      approvalDetails?.userApproval
                        .approvalHistoryItemsByDisplayEntityApprovalId
                    }
                    isPaymentLink={
                      approvalDetails?.userApproval.layoutType ===
                      TransactionLayout.CHECK_PAYMENT
                    }
                  />
                ) : (
                  <>
                    {layoutType === TransactionLayout.PO_SYSTEM_APPROVAL ||
                    layoutType ===
                      TransactionLayout.PO_SYSTEM_APPROVAL_ACCOUNTING ? (
                      <POItems
                        data={
                          approvalDetails?.userApproval
                            .approvalHistoryItemsByDisplayEntityApprovalId
                        }
                        onRefetch={() => refetchUserApproval?.()}
                        layoutType={layoutType}
                      />
                    ) : layoutType === TransactionLayout.TA_SYSTEM_APPROVAL ? (
                      <TripList
                        tripsData={
                          approvalDetails?.userApproval
                            .approvalHistoryItemsByDisplayEntityApprovalId
                        }
                        onRefetch={() => refetchUserApproval?.()}
                      />
                    ) : (
                      <InvoiceAccountingList
                        data={
                          approvalDetails?.userApproval
                            .approvalHistoryItemsByDisplayEntityApprovalId
                        }
                      />
                    )}
                  </>
                )}
              </Stack>
            )}
          {approvalDetails?.userApproval?.approvalSignaturesByEntityApprovalId
            .nodes &&
            approvalDetails?.userApproval?.approvalSignaturesByEntityApprovalId
              .nodes.length > 0 && (
              <Stack
                className={styles.tagsContainer}
                tokens={{ childrenGap: 20 }}
              >
                <Text variant="xLarge">Signatures</Text>
                <SignatureView
                  signatureData={
                    approvalDetails.userApproval
                      .approvalSignaturesByEntityApprovalId.nodes
                  }
                />
              </Stack>
            )}
        </>
      )}
    </DraggablePanel>
  );
};

export const ApprovalHistoryDetails = () => {
  const approvalLayoutType = useReactiveVar(setApprovalLayoutType);
  const styles = useStyles();
  const approvalCheckList = useReactiveVar(setApprovalHistoryNodes);
  const lastApprovalAction = useReactiveVar(setLastApprovalAction);
  const nextApproval = useReactiveVar(setNextApproval);
  const approvalCheckListLength = approvalCheckList?.length! || 0;
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const [isOpen, { setFalse: dismissPanel }] = useBoolean(true);
  const { addToast } = useToasts();
  const [processDone, setProcessDone] = useState<boolean>(false);
  const { approvalHistoryId } = useParams<{
    approvalHistoryId: string | undefined;
  }>();
  const client = useApolloClient();
  const [approvalState, setApprovalState] = useState<
    ApprovalActionTypeInput | undefined
  >();

  const approvalOrderBy = getApprovalOrderBy(approvalLayoutType);

  const [
    getApprovalHistory,
    {
      data: approvalHistoryDetails,
      loading: approvalHistoryLoading,
      variables,
      refetch: refetchApprovalHistory,
    },
  ] = useLazyQuery<ApprovalHistory, ApprovalHistoryVariables>(
    GET_APPROVAL_HISTORY_DETAILS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [processApproval, { loading: processApprovalLoading }] = useMutation<
    ApprovalHistoryActions,
    ApprovalHistoryActionsVariables
  >(APPROVAL_HISTORY_ACTIONS, { errorPolicy: 'all' });

  const getApprovalHistoryMemo = useCallback(getApprovalHistory, [
    approvalHistoryId,
  ]);

  useEffect(() => {
    if (approvalHistoryId && approvalHistoryId !== '') {
      getApprovalHistoryMemo({
        variables: {
          id: approvalHistoryId!,
          orderBy: approvalOrderBy,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalHistoryId, getApprovalHistoryMemo]);

  useEffect(() => {
    if (
      approvalHistoryDetails?.approvalHistory?.iconType
        ?.isApprovalActionAvailable
    ) {
      setApprovalState({
        ...lastApprovalAction!,
        entityApprovalBranchId:
          approvalHistoryDetails.approvalHistory.entityApprovalBranchId!,
        entityApprovalBranchRowTimestamp:
          approvalHistoryDetails.approvalHistory
            .entityApprovalBranchRowTimestamp!,
        isApproveBypassLowerLevels: undefined,
        comments: undefined,
      });
    } else setApprovalState(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalHistoryLoading]);

  const position = approvalCheckList?.findIndex(
    (item) => item.id === approvalHistoryDetails?.approvalHistory?.id
  );
  const navigateToPosition = position + 1;
  const hasMoreApproval = navigateToPosition < approvalCheckListLength;

  const onProcessApproval = async (willApproveNext: boolean) => {
    if (approvalState) {
      setNextApproval(willApproveNext);
      if (willApproveNext) setLastApprovalAction(approvalState);
      else setLastApprovalAction(undefined);
      const { errors, data } = await processApproval({
        variables: {
          input: {
            approveDeclineActions: [approvalState],
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_APPROVAL_HISTORY_DETAILS,
            variables,
          },
          {
            query: USER_PENDING_APPROVAL,
          },
        ],
      });
      if (errors?.length)
        addToast(`${errors[0].message}`, {
          appearance: 'error',
        });
      else {
        setProcessDone(true);
        addToast('Your approval have been processed', {
          appearance: 'success',
        });
        if (hasMoreApproval && willApproveNext) {
          history.replace(
            `/approval_history/approval/${approvalCheckList[navigateToPosition]?.id}`
          );

          const { isApproved, approvalType, entityId } = {
            ...data?.approvalHistoryActions?.approvalActionResults?.[0]
              ?.approvalHistory,
          };

          if (isApproved && entityId) {
            if (approvalType?._isSigningStandalone) {
              const observer = client.subscribe<
                TransactionDocumentPackageStatus,
                TransactionDocumentPackageStatusVariables
              >({
                query: DOCUMENT_PACKAGE_STATUS,
                variables: {
                  id: entityId,
                },
              });

              const subscription = observer.subscribe(({ data, errors }) => {
                if (errors)
                  addToast(
                    'Errors received while Subscribing to document package',
                    { appearance: 'error' }
                  );
                else {
                  const { document, status } = {
                    ...data?.transactionDocumentPackageStatus,
                  };
                  if (
                    status === DocumentPackageStatusType.REGENERATION_FAILURE
                  ) {
                    addToast(
                      'Report generation failed. Document package was not created',
                      { appearance: 'error' }
                    );
                  }
                  if (status === DocumentPackageStatusType.FAILURE) {
                    addToast('Error while creating Document package ', {
                      appearance: 'error',
                    });
                  }
                  if (document) {
                    addToast('Document package created', {
                      appearance: 'success',
                    });
                    client.cache.modify({
                      id: client.cache.identify({
                        ...approvalHistoryDetails?.approvalHistory,
                      }),
                      fields: {
                        _documentPackageId: () => {
                          return document.id;
                        },
                      },
                    });
                  }
                }
                subscription.unsubscribe();
              });
            } else if (approvalType?._isPurchaseOrderSystem) {
              const observer = client.subscribe<
                PurchaseOrderDocumentPackageStatus,
                PurchaseOrderDocumentPackageStatusVariables
              >({
                query: PO_DOCUMENT_PACKAGE_STATUS,
                variables: {
                  id: entityId,
                },
              });

              const subscription = observer.subscribe(({ data, errors }) => {
                if (errors)
                  addToast(
                    'Errors received while Subscribing to document package',
                    { appearance: 'error' }
                  );
                else {
                  const { document, status } = {
                    ...data?.purchaseOrderDocumentPackageStatus,
                  };
                  if (
                    status === DocumentPackageStatusType.REGENERATION_FAILURE
                  ) {
                    addToast(
                      'Report generation failed. Document package was not created',
                      { appearance: 'error' }
                    );
                  }
                  if (status === DocumentPackageStatusType.FAILURE) {
                    addToast('Error while creating Document package ', {
                      appearance: 'error',
                    });
                  }
                  if (document) {
                    addToast('Document package created', {
                      appearance: 'success',
                    });
                    client.cache.modify({
                      id: client.cache.identify({
                        ...approvalHistoryDetails?.approvalHistory,
                      }),
                      fields: {
                        _documentPackageId: () => {
                          return document.id;
                        },
                      },
                    });
                  }
                }
                subscription.unsubscribe();
              });
            } else if (approvalType?._isPaymentTrackingStandalone) {
              const observer = client.subscribe<
                PaymentDocumentPackageStatus,
                PaymentDocumentPackageStatusVariables
              >({
                query: PAYMENT_DOCUMENT_PACKAGE_STATUS,
                variables: {
                  id: entityId,
                },
              });

              const subscription = observer.subscribe(({ data, errors }) => {
                if (errors)
                  addToast(
                    'Errors received while Subscribing to document package',
                    { appearance: 'error' }
                  );
                else {
                  const { document, status } = {
                    ...data?.paymentDocumentPackageStatus,
                  };
                  if (
                    status === DocumentPackageStatusType.REGENERATION_FAILURE
                  ) {
                    addToast(
                      'Report generation failed. Document package was not created',
                      { appearance: 'error' }
                    );
                  }
                  if (status === DocumentPackageStatusType.FAILURE) {
                    addToast('Error while creating Document package ', {
                      appearance: 'error',
                    });
                  }
                  if (document) {
                    addToast('Document package created', {
                      appearance: 'success',
                    });
                    client.cache.modify({
                      id: client.cache.identify({
                        ...approvalHistoryDetails?.approvalHistory,
                      }),
                      fields: {
                        _documentPackageId: () => {
                          return document.id;
                        },
                      },
                    });
                  }
                }
                subscription.unsubscribe();
              });
            } else if (approvalType?._isTravelAuthSystem) {
              // TA_DOCUMENT_PACKAGE_STATUS

              const observer = client.subscribe<
                TravelAuthorizationDocumentPackageStatus,
                TravelAuthorizationDocumentPackageStatusVariables
              >({
                query: TA_DOCUMENT_PACKAGE_STATUS,
                variables: {
                  id: entityId,
                },
              });

              const subscription = observer.subscribe(({ data, errors }) => {
                if (errors)
                  addToast(
                    'Errors received while Subscribing to document package',
                    { appearance: 'error' }
                  );
                else {
                  const { document, status } = {
                    ...data?.travelAuthorizationDocumentPackageStatus,
                  };
                  if (
                    status === DocumentPackageStatusType.REGENERATION_FAILURE
                  ) {
                    addToast(
                      'Report generation failed. Document package was not created',
                      { appearance: 'error' }
                    );
                  }
                  if (status === DocumentPackageStatusType.FAILURE) {
                    addToast('Error while creating Document package ', {
                      appearance: 'error',
                    });
                  }
                  if (document) {
                    addToast('Document package created', {
                      appearance: 'success',
                    });
                    client.cache.modify({
                      id: client.cache.identify({
                        ...approvalHistoryDetails?.approvalHistory,
                      }),
                      fields: {
                        _documentPackageId: () => {
                          return document.id;
                        },
                      },
                    });
                  }
                }
                subscription.unsubscribe();
              });
            } else {
            }
          }
        }
      }
    }
    if (!processApprovalLoading) setApprovalState(undefined);
  };

  useEffect(() => {
    setLastApprovalAction(undefined);
    setApprovalPanelReactive(true);
    // Cleanup function to be executed on component unmount
    return () => {
      setApprovalPanelReactive(false);
    };
  }, []);

  const _onRenderHeader = () => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/approval_history', { processDone });
          dismissPanel();
          setLastApprovalAction(undefined);
          setApprovalLayoutType(undefined);
        }}
      >
        <Stack
          grow
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Stack verticalAlign="start">
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Text variant="xLarge">Approval</Text>
              {approvalHistoryDetails?.approvalHistory?.approvalType
                ?.approvalType && (
                <Text
                  variant="xLarge"
                  className={commonStyles.colorThemePrimary}
                >
                  {
                    approvalHistoryDetails?.approvalHistory?.approvalType
                      ?.approvalType
                  }
                </Text>
              )}
              {approvalHistoryDetails?.approvalHistory?.iconType && (
                <StatusIcon
                  approval
                  iconType={approvalHistoryDetails.approvalHistory.iconType}
                />
              )}

              <DocumentPackage
                documentPackageId={
                  approvalHistoryDetails?.approvalHistory?._documentPackageId
                }
              />
            </Stack>
            <Stack>
              <Text className={commonStyles.colorThemeRed}>
                {
                  approvalHistoryDetails?.approvalHistory
                    ?.additionalApprovalInformation
                }
              </Text>
            </Stack>
          </Stack>
          {approvalHistoryDetails?.approvalHistory
            ?._accountingStampTransactionReference && (
            <StamperView
              invoiceDetails={{
                _accountingStampDate:
                  approvalHistoryDetails?.approvalHistory?._accountingStampDate,
                _accountingStampTransactionReference:
                  approvalHistoryDetails?.approvalHistory
                    ?._accountingStampTransactionReference,
                _accountingStampUserName:
                  approvalHistoryDetails?.approvalHistory
                    ?._accountingStampUserName,
                _isAccountingEntryStampedComplete:
                  approvalHistoryDetails?.approvalHistory
                    ?._isAccountingEntryStampedCompleted,
                _isTransactionCancelled:
                  approvalHistoryDetails?.approvalHistory
                    ?._isTransactionCancelled,
              }}
            />
          )}
        </Stack>
      </PanelHeader>
    );
  };

  const _onRenderFooter = () => {
    return (
      <Stack className={styles.footerContainer}>
        <Stack
          horizontal
          tokens={{ padding: 25 }}
          className={styles.footerStyle}
          horizontalAlign="space-between"
        >
          {approvalHistoryDetails?.approvalHistory?.iconType
            ?.isApprovalActionAvailable && (
            <ApprovalButtons
              approvalData={approvalHistoryDetails?.approvalHistory!}
              onChange={(inputValue) => {
                setApprovalState(inputValue);
                setLastApprovalAction(inputValue);
              }}
              selectedApproval={{
                ...lastApprovalAction!,
                entityApprovalBranchId:
                  approvalHistoryDetails.approvalHistory
                    .entityApprovalBranchId!,
                entityApprovalBranchRowTimestamp:
                  approvalHistoryDetails.approvalHistory
                    .entityApprovalBranchRowTimestamp!,
                isApproveBypassLowerLevels: undefined,
                comments: undefined,
              }}
              refetchAllApproval={true}
            />
          )}

          <Stack horizontal tokens={{ childrenGap: 20 }}>
            {approvalHistoryDetails?.approvalHistory
              ?.isApprovalTreeViewerAllowed && (
              <HierarchyModal
                isApprovalEntry
                entityId={approvalHistoryDetails?.approvalHistory?.id!}
              />
            )}
            {approvalHistoryDetails?.approvalHistory
              ?.isApproverAddingAllowed && (
              <AddApproverModal
                approvalId={approvalHistoryDetails?.approvalHistory?.id}
                entityApprovalBranchId={
                  approvalHistoryDetails?.approvalHistory
                    ?.entityApprovalBranchId!
                }
                approvalOrderBy={approvalOrderBy}
              />
            )}
          </Stack>
        </Stack>
        {approvalState?.isApproval && (
          <>
            {processApprovalLoading && <ProgressIndicator />}
            <Stack
              className={styles.footerBackground}
              horizontal
              tokens={{ padding: 20, childrenGap: 10 }}
            >
              <Stack grow={1.5}>
                <DefaultButton
                  text="Confirm Approval"
                  styles={{ root: { height: 50 } }}
                  disabled={processApprovalLoading}
                  primary={hasMoreApproval ? !nextApproval : true}
                  onClick={() => onProcessApproval(false)}
                />
              </Stack>
              {hasMoreApproval && (
                <Stack grow={0.5}>
                  <DefaultButton
                    disabled={processApprovalLoading}
                    text="Confirm Approval + Select Next Approval"
                    primary={nextApproval}
                    styles={{ root: { height: 50 } }}
                    onClick={() => {
                      const current = setActiveOrderIndex();
                      current.clear();
                      setActiveOrderIndex(current);
                      onProcessApproval(true);
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </>
        )}
        {approvalState?.isDecline && (
          <Stack className={styles.footerBackground} tokens={{ padding: 20 }}>
            {processApprovalLoading && <ProgressIndicator />}
            <DefaultButton
              toggle
              primary={true}
              disabled={processApprovalLoading}
              className={styles.declineButton}
              selected={true}
              onClick={() => onProcessApproval(false)}
            >
              Confirm Decline
            </DefaultButton>
          </Stack>
        )}
      </Stack>
    );
  };

  const isMiscellaneousApproval =
    approvalHistoryDetails?.approvalHistory?.layoutType ===
      TransactionLayout.MISCELLANEOUS_APPROVAL &&
    approvalHistoryDetails?.approvalHistory
      ?.approvalHistoryItemsByDisplayEntityApprovalId?.nodes.length > 0;

  const isBatchTransaction =
    approvalHistoryDetails?.approvalHistory?.layoutType ===
    TransactionLayout.BATCH_TRANSACTION_APPROVAL;

  const { layoutType } = { ...approvalHistoryDetails?.approvalHistory };

  return (
    <DraggablePanel
      {...PanelCommonProps}
      initialWidth={1000}
      minWidth={1000}
      isBlocking={false}
      onRenderFooter={_onRenderFooter}
      onRenderHeader={_onRenderHeader}
      isOpen={isOpen}
    >
      {approvalHistoryLoading ? (
        <ShimmerView />
      ) : (
        <>
          <InvoiceHeader data={approvalHistoryDetails?.approvalHistory} />
          <Separator />
          {approvalHistoryDetails?.approvalHistory && (
            <MenuActions
              data={approvalHistoryDetails?.approvalHistory}
              approvalPanelType={ApprovalPanelTypes.APPROVAL_HISTORY}
              onRefetch={() => refetchApprovalHistory?.()}
            />
          )}
          <Separator />
          <InvoiceDetailedData
            data={approvalHistoryDetails?.approvalHistory}
            approvalOrderBy={approvalOrderBy}
            isHistory={true}
          />
          <Separator />
          {isMiscellaneousApproval && (
            <>
              <AdditionalInformation
                data={approvalHistoryDetails?.approvalHistory}
              />
              <Separator />
            </>
          )}

          {isBatchTransaction && (
            <BatchTransactionLayout
              data={
                approvalHistoryDetails?.approvalHistory
                  ?.approvalHistoryItemsByDisplayEntityApprovalId!
              }
            />
          )}

          {approvalHistoryDetails?.approvalHistory
            ?.approvalHistoryItemsByDisplayEntityApprovalId?.nodes &&
            approvalHistoryDetails.approvalHistory
              .approvalHistoryItemsByDisplayEntityApprovalId.nodes.length > 0 &&
            !isMiscellaneousApproval &&
            !isBatchTransaction && (
              <Stack tokens={{ padding: '0px 0px 0px 10px' }}>
                {isLayoutPaycycle(
                  approvalHistoryDetails?.approvalHistory.layoutType!
                ) ? (
                  <InvoicePaymentsList
                    data={
                      approvalHistoryDetails?.approvalHistory
                        .approvalHistoryItemsByDisplayEntityApprovalId
                    }
                    isPaymentLink={
                      approvalHistoryDetails?.approvalHistory.layoutType ===
                      TransactionLayout.CHECK_PAYMENT
                    }
                  />
                ) : (
                  <>
                    {layoutType === TransactionLayout.PO_SYSTEM_APPROVAL ||
                    layoutType ===
                      TransactionLayout.PO_SYSTEM_APPROVAL_ACCOUNTING ? (
                      <POItems
                        data={
                          approvalHistoryDetails?.approvalHistory
                            .approvalHistoryItemsByDisplayEntityApprovalId
                        }
                        onRefetch={() => refetchApprovalHistory?.()}
                        layoutType={layoutType}
                      />
                    ) : layoutType === TransactionLayout.TA_SYSTEM_APPROVAL ? (
                      <TripList
                        tripsData={
                          approvalHistoryDetails?.approvalHistory
                            .approvalHistoryItemsByDisplayEntityApprovalId
                        }
                        onRefetch={() => refetchApprovalHistory?.()}
                      />
                    ) : (
                      <InvoiceAccountingList
                        data={
                          approvalHistoryDetails?.approvalHistory
                            .approvalHistoryItemsByDisplayEntityApprovalId
                        }
                      />
                    )}
                  </>
                )}
              </Stack>
            )}
          {approvalHistoryDetails?.approvalHistory
            ?.approvalSignaturesByEntityApprovalId.nodes &&
            approvalHistoryDetails.approvalHistory
              .approvalSignaturesByEntityApprovalId.nodes.length > 0 && (
              <Stack
                className={styles.tagsContainer}
                tokens={{ childrenGap: 20 }}
              >
                <Text variant="xLarge">Signatures</Text>
                <SignatureView
                  signatureData={
                    approvalHistoryDetails.approvalHistory
                      .approvalSignaturesByEntityApprovalId.nodes
                  }
                />
              </Stack>
            )}
        </>
      )}
    </DraggablePanel>
  );
};
